/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */
 
body {
   
  color: #5a5a5a;
   
}
.font{
  font-family: 'Libre Franklin', sans-serif;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 2rem;
 }
 
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}



/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

.text-container {
  width: 150px;
  height: 50px;
  position: absolute;
   bottom: 30%;
   left: 65%;
 
 
}

.box {
  width: 51vh;
  height:75px;
  position: absolute;
  top: 0;
  left: 0; 
  background: #FF2B3A; 
 
 
  
}

 .bg-box{
  margin: -1rem 0.5rem;
  transform: translateY(-100px);
  animation: 0.4s slideIn ease-in-out forwards 1s;
  opacity: 0;
  
 }

@keyframes slideIn {
 
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


.overlay {
   
  z-index: 9;
  padding: 0.2rem 1.5rem;
   height: 100%;
  background: #ffffff;
  width : 50vh;
  block-size: fit-content;
  block-size:-webkit-max-content;
  block-size: -moz-max-content;
  transform: translateX(-100px);
  animation: 0.5s slideIn ease-in-out forwards 1s;
  opacity: 0; 
}
 
 .carousel-title{
   font-size : 1.5rem;
   
 }
 .carousel-subtitle{
  font-size : 1rem;
}
 
.overlay .carousel-title,
.overlay .carousel-subtitle{
  color: #000;
  font-family: 'Libre Franklin', sans-serif;
}
 
@media (min-width : 767px){
  .carousel-control-prev,
.carousel-control-next{
  
  background :rgba(51,51,51,.5);
  margin : auto 2rem;
  
  padding : 0.5rem 0.3rem ;
  width: 0%;
  max-width: 5%;
  height: -moz-max-content;

  height: fit-content;
  
  
}
.carousel-control-prev-icon,
.carousel-control-next-icon{
  height: fit-content; 
  width: fit-content;
}
 

}
 
@media (max-width : 767px) {
  .carousel-caption button, 
  .carousel-caption button:hover{
    border:none;
     
   
  }

  .dropdown-menu.show {
    display: contents;
  }
  .carousel-item {
    height : 38vh; 
   
  }
  .carousel-control-prev,
  .carousel-control-next{
    
    background :rgba(51,51,51,.5);
    margin : 12vh 0rem;
    
    padding : 0.5rem 0rem;
    height: fit-content; 
    height: -moz-max-content;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon{
    width:fit-content; /*this must stay */
    height: fit-content;
  }
   

  div#carousel-item  img {
    height : 24vh;
    
  }
 
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

 

.carousel-caption {
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

  .carousel-caption {
    position: absolute;
    top: 29vh;
    left: 0;
    font-size : 0.9rem; 
    opacity:0;  /* make things invisible upon start */
    -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;
  
    -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
  
    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;
  }
  .carousel-caption::after{
    content: '>';
    transform: translateX(100px);
    animation: 0.5s slideIn ease-in-out forwards 0.5s;
  }
  .carousel-indicators > *{
    display: none;
  }
 .text-container {
    position: absolute;
    top: 24vh;
    left :0;
  }
  .box {
    width: 300vh; 
    position: absolute;
    top: 0;
    left: 0; 
    background: #FF2B3A; 
   }
  
   .bg-box{ 
     margin: 0!important;
     height: 100vh;
    transform: translateX(100px);
    animation: 0.5s slideIn ease-in-out forwards ;
    opacity: 0;
    
   }
   
.overlay {
   z-index: 9; 
   background: #ffffff;  
   transform: translateX(100px);
  animation: 0.5s slideIn ease-in-out forwards 0.5s;
  opacity: 0;  
  height:10vh;
  width: 90vw; 
  left: 1.2rem;
  padding : 0.2rem 1rem;
 top : 0%;
 margin-top: -0.7rem;
}
 
 
 .carousel-title{
   font-size : 1rem;
 }
 .carousel-subtitle{
   font-size : 1rem;
 }
 
.overlay .carousel-title,
.overlay .carousel-subtitle{
  color: #000;
  
}
}
 
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 50%;
  height: 100%;
}

.carousel-indicators li {
  
  background-color: #ffffff;
   
}
.carousel-indicators .active {
 
  background-color: #FF2B3A;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
 /* img{
   height: 50%;
   width: 70%;
 } */



 /*navigation */ 

 



 
/* #Primary
================================================== */

 
::selection {
	color: #fff;
	background-color: #FF2B3A;
}
::-moz-selection {
	color: #fff;
	background-color: #FF2B3A;
}


/* #Navigation
================================================== */

.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 20px 0;
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on {
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	padding: 10px 0;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img{
	height: 100px;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navigation-wrap{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navbar{
	padding: 0;
}
.navbar-brand img{
	height: 80px;
	width: auto;
	display: block;
   
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navbar-toggler {
	float: right;
	border: none;
	padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
	outline: none;
}
.navbar-light .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	 
    transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after, 
.navbar-light .navbar-toggler-icon:before{
	width: 24px;
	position: absolute;
	height: 1px; 
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after{
	top: 8px;
}
 
.nav-link{
	color: #212121 !important;
	font-weight: 500;
  font-size : 18px;
  transition: all 200ms linear;
  font-family: 'Libre Franklin', sans-serif;
  margin : 0 !important;
 
}
.nav-item:hover .nav-link{
	color: #000000 !important;
  margin : 0 !important;
  padding : 0 !important;
}
.nav-item.active .nav-link{
	color: rgb(255, 255, 255) !important;
}
.nav-link {
	position: relative;
	padding: 0px 0 !important;
	display: inline-block;
}
.nav-item::after{
 
	opacity: 0;
    transition: all 0ms forwards;
 
}
 
.nav-item:hover:after{
  bottom: 0;
	opacity: 1;
 
}
.nav-item.active:hover:after{
	opacity: 0;
 
}
.nav-item{
	position: relative;
    transition: all 200ms linear;
    
}
.nav-active{
  border-bottom : 2px solid #FF2B3A;
 
}

/* #Primary style
================================================== */

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}
.section {
    position: relative;
	width: 100%;
	display: block;
}
.full-height {
    height: 100vh;
}
.over-hide {
    overflow: hidden;
}
.absolute-center {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
  margin-top: 40px;
	transform: translateY(-50%);
	z-index: 20;
}
h1{
	font-size: 48px;
	line-height: 1.2;
	font-weight: 700;
	color: #212112;
	text-align: center;
}
p{
	text-align: center;
	margin: 0;
	padding-top: 10px;
	opacity: 1;
	transform: translate(0);
    transition: all 300ms linear;
    transition-delay: 1700ms;
}
body.hero-anime p{
	opacity: 0;
	transform: translateY(40px);
    transition-delay: 1700ms;
}
h1 span{
	display: inline-block;
    transition: all 300ms linear;
	opacity: 1;
	transform: translate(0);
}
body.hero-anime h1 span:nth-child(1){
	opacity: 0;
	transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(2){
	opacity: 0;
	transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(3){
	opacity: 0;
	transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(4){
	opacity: 0;
	transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(5){
	opacity: 0;
	transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(6){
	opacity: 0;
	transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(7){
	opacity: 0;
	transform: translateY(-40px);
}
body.hero-anime h1 span:nth-child(8){
	opacity: 0;
	transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(9){
	opacity: 0;
	transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(10){
	opacity: 0;
	transform: translateY(-20px);
}
h1 span:nth-child(1){
    transition-delay: 1000ms;
}
h1 span:nth-child(2){
    transition-delay: 700ms;
}
h1 span:nth-child(3){
    transition-delay: 900ms;
}
h1 span:nth-child(4){
    transition-delay: 800ms;
}
h1 span:nth-child(5){
    transition-delay: 1000ms;
}
h1 span:nth-child(6){
    transition-delay: 700ms;
}
h1 span:nth-child(7){
    transition-delay: 900ms;
}
h1 span:nth-child(8){
    transition-delay: 800ms;
}
h1 span:nth-child(9){
    transition-delay: 600ms;
}
h1 span:nth-child(10){
    transition-delay: 700ms;
}
body.hero-anime h1 span:nth-child(11){
	opacity: 0;
	transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(12){
	opacity: 0;
	transform: translateY(50px);
}
body.hero-anime h1 span:nth-child(13){
	opacity: 0;
	transform: translateY(20px);
}
body.hero-anime h1 span:nth-child(14){
	opacity: 0;
	transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(15){
	opacity: 0;
	transform: translateY(50px);
}
h1 span:nth-child(11){
    transition-delay: 1300ms;
}
h1 span:nth-child(12){
    transition-delay: 1500ms;
}
h1 span:nth-child(13){
    transition-delay: 1400ms;
}
h1 span:nth-child(14){
    transition-delay: 1200ms;
}
h1 span:nth-child(15){
    transition-delay: 1450ms;
}
#switch,
#circle {
	cursor: pointer;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
} 
#switch {
	width: 60px;
	height: 8px;
	border: 2px solid #8167a9;
	border-radius: 27px;
	background: #000;
	position: relative;
	display: block;
	margin: 0 auto;
	text-align: center;
	opacity: 1;
	transform: translate(0);
    transition: all 300ms linear;
    transition-delay: 1900ms;
}
body.hero-anime #switch{
	opacity: 0;
	transform: translateY(40px);
    transition-delay: 1900ms;
}
#circle {
	position: absolute;
	top: -11px;
	left: -13px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background: #000;
}
.switched {
	border-color: #000 !important;
	background: #8167a9 !important;
}
.switched #circle {
	left: 43px;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
	background: #fff;
}
.nav-item .dropdown-menu {
    transform: translate3d(0, 0px, 0);
    visibility: hidden;
    opacity: 0;
	max-height: 0;
    display: block;
	padding: 0;
	margin: 0;
    transition: all 200ms linear;
}
 
.nav-item.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
	max-height: 100vh;
  margin: 0 0;
	transform: translate(0, 0px);
	 
}
.dropdown-menu {
	padding: 10px!important;
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
	color: #212121;
	background-color: #fcfaff;
	border: none;
	border-radius: 3px;
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    transition: all 200ms linear;
}
 

.dropdown-item {
	padding: 3px 15px;
	color: #212121;
	border-radius: 2px;
    transition: all 200ms linear;
}
.dropdown-item:hover, 
.dropdown-item:focus {
	color: #fff; 
}

body.dark{
	color: #fff;
	background-color: #1f2029;
}
body.dark .navbar-brand img{
  filter: brightness(100%);
}
body.dark h1{
	color: #fff;
}
body.dark h1 span{
    transition-delay: 0ms !important;
}
body.dark p{
	color: #fff;
    transition-delay: 0ms !important;
}
body.dark .bg-light {
	background-color: #14151a !important;
}
body.dark .start-header {
	box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}
body.dark .start-header.scroll-on {
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
body.dark .nav-link{
	color: #fff !important;
}
body.dark .nav-item.active .nav-link{
	color: #999 !important;
}
body.dark .dropdown-menu {
	color: #fff;
	background-color: #1f2029;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}
body.dark .dropdown-item {
	color: #fff;
}
 
body.dark .navbar-light .navbar-toggler-icon:after, 
body.dark .navbar-light .navbar-toggler-icon:before{
	background-color: #fff;
}
body.dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}



/* #Media
================================================== */

@media (max-width: 767px) { 
	h1{
		font-size: 38px;
	} 
	 
	 
	.dropdown-toggle::after {
		 
		display: block;
		 
		content: "";
		 
		transition: all 200ms linear;
	}
	 
	.dropdown-toggle[aria-expanded="true"]::after{
		transform: rotate(190deg);
		opacity: 0;
	}
	.dropdown-menu {
		padding: 0 !important;
		background-color: transparent;
		box-shadow: none;
		transition: all 200ms linear;
	}
	.dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
		margin-top: 10px !important;
		margin-bottom: 20px !important;
	}
	@media (max-width: 960px) {
		.dropdown-menu {
			padding: 0 !important;
			background-color: transparent;
			box-shadow: none;
			transition: all 200ms linear;
		}
	  }
	body.dark .nav-item::before {
		background-color: #fff;
	}
	body.dark .dropdown-toggle::after {
		background-color: #fff;
	}
	body.dark .dropdown-menu {
		background-color: transparent;
		box-shadow: none;
	}
}
 hr.sparation{
   border-color : 1px solid #F2F2F2; 
   margin : 0 !important;
   padding : 0 !important;
 }
hr.new4 {
  border-bottom: 2px solid #FF2B3A;
   margin: 0!important;

  }
  hr.produit {
    border-bottom: 8px solid #FF2B3A;
    margin-top: 0  !important;
   }
  hr.test {
    border-bottom: 0.5px solid #FF2B3A;
	width:10%;
  }
span.before-title{
  border-left: 10px solid #FF2B3A;
  
}
  /* social links */
  .btn-outline { 
	margin-top: 5px; 
 	color: #000; 
 	transition: all .3s ease-in-out; 
   }
   
   
   .btn-social { 
	 display: inline-block; 
	 width: 34px; 
	 height: 34px; 
	  
	 border-radius: 100%; 
	 text-align: center; 
	 font-size: 22px; 
	 line-height: 33px; 
   }
   
   
   
   .list-inline { 
	 padding-left: 0; 
	 list-style: none; 
	 margin-left: -5px; }
   
   .list-inline > li { 
	 display: inline-block;  
	 padding-left: 5px; 
	 padding-right: 5px; 
   }
   
   .social-links { 
	 -webkit-animation: fade-in-down 0.9s; 
	 animation: fade-in-down 0.9s; 
	 -webkit-animation-delay: 0.2s; 
	 animation-delay: 0.2s; 
   }
   
   /* Animations */
   @-webkit-keyframes fade-in-down { 0% 
	 { opacity: 0; -webkit-transform: translateY(-10px); transform: translateY(-10px); 
	 }
	 100% { 
	   opacity: 1; 
	   -webkit-transform: translateY(0); 
	   transform: translateY(0); } }
   @keyframes fade-in-down { 
	 0% { opacity: 0; 
	   -webkit-transform: translateY(-10px); 
	   transform: translateY(-10px); 
	 }
	 100% { opacity: 1; 
	   -webkit-transform: translateY(0); 
	   transform: translateY(0); 
	 } 
   }
 

  /* card div */
  
  .news-1 .news {
    background: #fff; }
  .news-1 .blog-grids h3 a {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-top: 15px;
    display: block;
    color: var(--title-color);
    text-transform: capitalize; }
  .news-1 .blog-info p {
    font-size: 16px;
    color: var(--text-color);
    line-height: 24px;
    padding: 0.8em 0 1em 0;
    border-bottom: 1px solid #ddd; }
  .news-1 .blog-grids h3 a:hover {
    color: var(--opposite-color); }
  .news-1 .blog-info {
    padding: 10px 0px 0; }
    .bottom-left {
      position: absolute;
      bottom: 0px;
      left: 16px;
    }
  .news-1 .blog-grid {
    background-color: #F9FAFB;
    border: 1px solid #f1f4f7;
    padding: 30px;
    border-radius: 4px;
    height:100%; }
    .news-1 .blog-grid-pst {
      background-color: transparent;
      border: 1px solid transparent;
      padding: 30px;
      border-radius: 4px;
      height:100%; }
   
  .news-1 .blog-grids a img {
    border-radius: 4px; }
  .news-1 .blog-info ul {
    padding: 0;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    grid-gap: 10px;
    margin: 1em 0em .5em; }
  .news-1 .blog-info ul li {
    display: inline-block; }
  .news-1 .blog-info ul li,
  .news-1 .blog-info ul li a {
    color: #999; }
  .news-1 .blog-info ul li a:hover {
    color: #333; }
  @media only screen and (max-width: 1080px) {
    .news-1 .blog-grids h3 a {
      font-size: 18px; }
    .news-1 .blog-info ul li,
    .news-1 .blog-info ul li a {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    .news-1 .blog-info ul li,
    .news-1 .blog-info ul li a {
      font-size: 16px; } }
  .news-1 figure {
    overflow: hidden;
    margin: 0px;
    border-radius: 4px; }
  .news-1 .blog-grid img {
    display: block;
    width: 100%; }
  .news-1 .blog-grid#zoomIn img {
    transform: scale(1);
    transition: .3s ease-in-out; }
  .news-1 .blog-grid#zoomIn:hover img {
    transform: scale(1.2); }
	.img-client {
		float: left;
		width:  50%;
		height: 50%;
		object-fit: cover;
	}
 
	.img-products {
		float: left;
		width:  75%;
		height: 75%;
		object-fit: cover;
	}

/* footer */
ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #ffffff;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #FF2B3A;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-text p {
  margin-bottom: 10px;
  font-size: 14px;
      color: #000000;
  line-height: 20px;
}
.footer-social-icon span {
  color: rgb(0, 0, 0);
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: rgb(0, 0, 0);
  font-size: 16px;
  margin-right: 15px;
}
 
 
.footer-widget-heading h3 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #FF2B3A;
}
.footer-widget ul li {
  display: inline-block;
   width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: #FF2B3A;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #FF2B3A;
    padding: 13px 20px;
    border: 1px solid #FF2B3A;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #ffffff;
  
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #000000;
}
.copyright-text p a{
  color: #FF2B3A;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #FF2B3A;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.footer-section #movetop {
	display: none;
	position: fixed;
	bottom: 75px;
	right: 15px;
	z-index: 99;
	font-size: 18px;
	cursor: pointer;
	color: #222;
	width: 36px;
	height: 36px;
	line-height: 36px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	-o-border-image: initial;
	border-image: initial;
	outline: none;
	background: #FF2B3A;
	padding: 0px;
	border-radius: 4px;
	transition: all 0.5s ease-out 0s; }
	 
.container-pp img{
		height: 350px;
	  }

.list{
	list-style: none
}
 
.img-fluid-fb{
     
    width:  500px;
    height: 500px;
    
  }
  .error {
    position: absolute;
    color: #FF2B3A;
    font-size: .6rem;
  }
 /* loading page */ 
 /* Loading Page Wave */

.loading-page-container,
.loading-page-container > * {
  box-sizing: border-box;
}

.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #FF2B3A;
  box-shadow: 0 0 20px #666666;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .75;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #FF2B3A;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #666666;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
 /***************/
 input.form-control, textarea.form-control, select.form-control, input.form-control:focus, textarea.form-control:focus, select.form-control:focus {
	background: #F5F7F8;
  }
  input.form-control[placeholder]{
    text-transform: capitalize;
     
  }
  input.form-control:focus,
  textarea.form-control:focus,
  select.form-control:focus{
    border-color: transparent;
    outline: 0;
    outline: thin dotted \9;
    -moz-box-shadow: 0 0 8px #5A6268;
    box-shadow: 0 0 8px #5A6268 !important;
  }
  /* button */
  button {
    transition: all .5s ease;
    color: #fff;
    border: 3px solid white;
    font-family:'Montserrat', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    font-size: 17px;
    background-color : transparent;
    padding: 10px;
    outline: none;
    border-radius: 4px;
}
button:hover {
    color: #001F3F;
    background-color: #fff;
}
/*phone*/
@media (max-width: 767px) { 
  #img-banner{
    height:20vh; 
  }
  #map{
    height:35vh;
  }
}
@media(min-width : 767px){
  #map{
    height:50vh;
  }
}
/*******/
/*admin links */
.admin-links{
	color: #212121 !important;
	font-weight: 500;
    transition: all 200ms linear;
}
.nav-item:hover .admin-links{
	color: #000000 !important;
}
.nav-item.active .admin-links{
	color: rgb(255, 255, 255) !important;
}
.admin-links {
	position: relative;
	padding: 0px 0 !important;
	display: inline-block;
}
body.dark .admin-links{
	color: #fff !important;
}
body.dark .nav-item.active .admin-links{
	color: #999 !important;
}
/*************************************/
/* slogan page entreprise hedaer */
 
.body-slogan {
  text-align:center;
  background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
  color:#FF2B3A; 
  font-weight:300;
  font-size:32px; 
  padding-top:20vh;
  height:50vh; 
  width : 90%;
  margin : 0 auto;
  overflow:hidden;
   
}
@media (max-width: 767px) { 
  .body-slogan {
    text-align:center;
    background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
    color:#FF2B3A; 
    font-weight:100;
    font-size:22px; 
    padding-top: 8vh;
    height: 25vh; 
    width : 90%;
    margin : 0 auto;
    overflow:hidden;
  }
   
 
}
 
  .large-header {
    position: relative;
    width: 90%;
     
    max-height: 50vh;
    text-align:center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin : 0 auto;
}

.large-header img { 
     height: 50vh;
     
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
}
.head-slogan,
.slogan {
  display:inline-block;
  overflow:hidden;
  white-space:nowrap;
}
.head-slogan { 
animation: showup 7s infinite;
}
.slogan {
  width:0px;
  animation: reveal 7s infinite;
}

.slogan span {
  margin-left:-355px;
  animation: slidein 7s infinite;
}

@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:0;}
}

@keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
    100% { margin-left:0px; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    30% {width:355px;}
    80% {opacity:1;}
    100% {opacity:0;width:355px;}
}
.box-pe{
  width:100%;
  overflow: hidden;
}
/*team*/
.team{
  text-align:center;
  background-color: #F5F7F8;
}
.team svg { 
  width: 8rem;
  height: 8rem;
  
}
.team h3{
  text-align: center;
  padding: 0 1.5rem;
  font-size: 1.5rem;
}

h3.emails-team{ 
  font-weight: 100;
  font-size: initial;
}
/***********************************/
/*page service */
.bg-gris{
  background-color : #F5F7F8;
}
.imgs-ps img{
  background-size: contain;
    background-position: right right;
    background-repeat: no-repeat;

}
/**************/
/*page result*/

 .card-result {
  width: 280px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px #1b1b1b33;
  margin: 15px;
  transition: all 0.3s;
}

 .card-image {
  width: 100%;
  height: 25vh;
  border-radius: 5px 5px 0 0;
}

 .card-image img {
  width: 100%;
  max-width: 100%;
  height: 25vh;
  border-radius: 5px 5px 0 0;
  transition: all 0.3s;
}

 .card-description {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  padding: 25px;
}

 .card-description h3 {
  color: #222222;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}

 

 .card-description a {
  color: #FF2B3A;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}

 .card-result:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 30px -5px rgba(10, 16, 34, 0.2);
}

 .card-image img:hover {
  opacity: 0.8;
}

/***********/
/***********************************/
/* btn sticky phone*/

.fixed-tabs-Whats {
  position: fixed;
  right: 0px;
  bottom: 60px;
  z-index: 100;
}
.aba-whatsphone {
  background: transparent;
  height: 60px;
  width: 70px;
  border-radius: 30px 0 0 30px;
  transition: 0.7s ease;
}

.aba-whatsphone:hover {
  width: 280px;
  transition: 0.7s ease;
  background: #FF2B3A;
}
.aba-whatsphone-icone { 
  background-repeat: no-repeat;
  width: 280px;
  background-size: 44px;
  background-position: 16px 7px;
  padding: 7px;
}
.aba-whatsphone-icone a {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  margin-left: 70px;
  text-decoration: none;
  height: 60px;
  font-family: sans-serif;
  text-decoration: none;
}
.aba-whatsphone-icone a strong {
  display: block;
  font-size: 18px;
  margin-left: 70px;
}

/*******************/

/* test carousel produits*/

 .owl-item {
  margin : 0 1rem;
 }

.bbb_viewed_prev {
  margin-right: 15px
}

.bbb_viewed_slider_container {
  padding-top: 13px
}

.bbb_viewed_item {
  width: 100%;
  background: #ffffff;
  border-radius: 2px; 
  padding-bottom: 25px;
  
}

 

.bbb_viewed_image img { 
  max-width: 100%;
  width:100%;
 
}

.bbb_viewed_content {
  width: 100%; 
}
 
 

 
/**************************************/
.image-entreprise-gst img{
height : 27vh;
width : 70vh;
 }
 /****************************************/
 .gst-intro{
 font-size : 1.1rem;
 }
 /*hamburger */
 .hamburger .line{
  width: 50px;
  height: 5px;
  background-color: #FF2B3A;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
  color: transparent;
  border-color: transparent;
}

#hamburger-9{
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger-9.is-active{
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-9:before{
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 70px;
  height: 70px; 
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

 

#hamburger-9.is-active .line{
  width: 35px;
}

#hamburger-9.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-9.is-active .line:nth-child(1){
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
}

#hamburger-9.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-13px) rotate(90deg);
}
.navbar-light .navbar-toggler {
  color: transparent;
  border-color: transparent;
}
.sidebar-menu {
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -300px;
  transform: translateX(0);
  transition: 0.3s ease all;
  background: #333;
}
.sidebar-menu.open {
  transform: translateX(300px);
}
.sidebar-menu .menu > li > a {
  padding: 15px 20px;
  color: #fff;
}
/************************/
/* -------------------------------- 

Primary style

-------------------------------- */
.breadcrumb-link:hover{
  color : #FF2B3A; 
}
 
 
@media only screen and (min-width: 1170px) {
  .breadcrumb-section {
    padding: 0em 0;
  } 
}

 
/* -------------------------------- 

Basic Style

-------------------------------- */
.cd-breadcrumb {
  width: 100%;
  max-width: 100%;
  padding: 0em 1em; 
  background-color: #edeff0; 
}
.cd-breadcrumb::after   {
  clear: both;
  content: "";
  display: block;
}
.cd-breadcrumb li  {
  display: inline-block;
   
}
.cd-breadcrumb li::after    {
  /* this is the separator between items */
  display: inline-block;
  content: '\00bb'; 
  color: #a5959e;
}
.cd-breadcrumb li:last-of-type::after {
  /* hide separator after the last item */
  display: none;
}
.cd-breadcrumb li > * {
  /* single step */
  display: inline-block;
  font-size: 1rem;
  color: #FF2B3A;
}
.cd-breadcrumb li.current > *{
  /* selected step */
  color: #000;
}
 
.cd-breadcrumb.custom-separator li::after, .custom-separator li::after {
  /* replace the default separator with a custom icon */
  content: '';
  height: 16px;
  width: 16px;
  background: url("../src/components/icons/crumbBread.svg") no-repeat center center;
  vertical-align: middle;
}
/** facebook*/
.box-fb{
  position: relative;
  overflow: hidden; 
}
.box-fb img{
  width:100%;
  height: 30vh;
}
.box-fb .over-layer{
  width: 100%;
  height: 30vh;
  position: absolute;
  top: 0;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  transition: all 0.5s ease 0s;
}
.box-fb:hover .over-layer{
  opacity: 1;
}
.box-fb .box-content{
  width: 100%;
  position: absolute;
  top: -100%;
  padding: 10px 20px;
  transition: all 0.5s ease 0s;
}
.box-fb:hover .box-content{
  top: 0;
}
.box-fb .box-content:after{
  content: "";
  width: 30%;
  display: block;
  margin-top: 20px;
  border-bottom: 2px solid #fff;
}
.box-fb .title{
  font-size: 16px;
  font-family: 'Libre Franklin', sans-serif ;
  color: #fff;
  margin-bottom: 5px;
  margin-top : 35px;
  text-transform: capitalize;
}
 
.box-fb .links{
  padding: 0 30px 0 0;
  margin: 0;
  list-style: none;
  width: 100%;
  position: absolute;
  bottom: -100%;
  text-align: right;
  transition: all 0.5s ease 0s;
}
.box-fb:hover .links{
  bottom: 15%;
}
.box-fb .links li{
  display: inline-block;
  margin-right: 2px;
}
.box-fb .links li.likes a{
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #FF2B3A;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.box-fb .links li.date_fb a{
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #fff; 
  text-align: center;
  transition: all 0.3s ease 0s;
}
.box-fb .links li.likes a:hover{
  
  cursor: default;
}
@media only screen and (max-width: 990px) {
  .box-fb{ margin-bottom: 0px; }
}
@media only screen and (max-width: 479px) {
  .box-fb .title{
    font-size : 14px;
     margin-top: 0px; }
}
span.date{
  color :#fff;
  margin: 1rem 0;
}
 
/*********************/
.react-multiple-carousel__arrow{
  z-index: 900;
}
.test-nav-scroll{
  box-shadow: 0px 2px 2px #cecece;
}
/* facebook sst */
 
 
.img-fluid-fb {
  float: center;
  width: 20vh;
  height : 250px;
   object-fit: cover;
  
}
/************/
/* button dud */

.button { 
  background: none;
  border: 2px solid #FF2B3A;
  color : #000; 
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  font-size: bold; 
   font-family: 'Libre Franklin', sans-serif;
}
.button:after {
  position: absolute;
  content: "";
  width: 0;
  bottom: 0;
  left: auto;
  right: -15%;
  transition: 0.5s;
  transform: skewX(25deg);
  background: #FF2B3A;
  height: 100%;
  z-index: -1;
}
.button:hover {
  cursor: pointer;
  background-color:  #FF2B3A;
  color: #fff;
}
.button:hover:after {
  width: 135%;
  transition: 0.5s;
  transform: skewX(25deg);
  left: -25%;
}
/**************/
.blur-body .App>:not(.wrapper-navbar)
 {
  filter: blur(3px);
  overflow: hidden;
  
}

 
  .icon-navbar {
    fill :#FF2B3A;
     width: 1.5rem; 
    height : 1.5rem;
     
}
 

 .nav-link-phone{
   border-bottom: 1px solid #cecece;
   
 }
 .home-block img{
   width : 100%;
   height : 50%;
 }

 @media (max-width : 767px) {
   hr.separte{
    display: block;
    height: 2px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
   }
 }
 @media (min-width : 767px) {
  hr.separte{
   display: none;
    
  }
}
.footer-logo {
  margin-bottom: 20px;
}
.footer-logo img {
    max-width: 300px;
}
 
@media (max-width : 767px) {
  .nav-item.show .dropdown-menu {
    opacity: 1;
     display :contents;
     
}
.footer-logo{
  display:none;
}
.footer-leica-img{ 
  min-height: 100%;
  min-width: 100%;
}
}
img.img-categories,
img.products-img {
  width: 100%;
  height: 50%;
}

 
/*! portrait ipad  */
@media only screen and 
(orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (device-aspect-ratio: 3/4) {
    .dropdown-menu.dropdown-menu.show {
      display: contents;
    }
.carousel-item,
.carousel-item img {
  height: 30vh;
}
.carousel-indicators > *{
  display: none;
}
.carousel-caption {
      
  top: 23vh;
  left: 0; 
      font-size : 0.9rem; 
      opacity:0;   
      -webkit-animation:fadeIn ease-in 1;   
      -moz-animation:fadeIn ease-in 1;
      animation:fadeIn ease-in 1;
    
      -webkit-animation-fill-mode:forwards;   
      -moz-animation-fill-mode:forwards;
      animation-fill-mode:forwards;
    
      -webkit-animation-duration:1s;
      -moz-animation-duration:1s;
      animation-duration:1s;
  
}
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 .carousel-control-prev,
 .carousel-control-next{
   
   background :rgba(51,51,51,.5);
   margin : 15% 0rem;
   
   padding : 0.5rem 0rem;
   height: fit-content; 
   height: -moz-max-content;
 }
  
.text-container {
   
  position: absolute;
   bottom: 25%;
   left: 48%;
   
 
}

.box {
  width: 31vh;
  height:100%;
  position: absolute;
  top: 0;
  left: 0; 
  background: #FF2B3A; 
 
 
  
}

 .bg-box{
  margin: -1rem 0.5rem;
  transform: translateY(-100px);
  animation: 0.4s slideIn ease-in-out forwards 1s;
  opacity: 0;
  
 }

@keyframes slideIn {
 
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


.overlay {
   
  z-index: 9;
  padding: 0.2rem 1.5rem;
   height: 100%;
  background: #ffffff;
  width :30vh;
  block-size: fit-content;
  block-size:-webkit-max-content;
  block-size: -moz-max-content;
  transform: translateX(-100px);
  animation: 0.5s slideIn ease-in-out forwards 1s;
  opacity: 0; 
}
.overlay .carousel-title{
  font-size : 1rem;
}

 
.sparation{
  display: none;
}
.team{
  text-align:center;
  background-color: #F5F7F8;
  height : 100%;
}
 
.team h3{
  text-align: center;
  padding: 0 0.1rem;
  font-size: 0.9rem;
}

h3.emails-team{ 
  font-weight: 100;
  font-size: 0.9rem;
}
.image-entreprise-gst img{
  height : 37vh;
  width : 80vh;
   }
   .categories img.img-categories {
    width: 100%;
   height: 35%;
  }
  .products img.products-img{
    width: 100%;
    height: 40%;
  }
  #img-banner{
    height:30vh; 
  }
  .body-slogan {
    text-align:center;
    background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
    color:#FF2B3A; 
    font-weight:300;
    font-size:32px; 
    padding-top:12vh;
    height:30vh; 
    width : 90%;
    margin : 0 auto;
    overflow:hidden;
     
  }
  h3.h4.font.titre-acc {
    font-size: 1rem;
}
hr.sparation{
  display:none;
}
p.gst-intro{
  font-size: 0.9rem;
}
.home-block img{
  width : 100%;
  height : 40%;
}
.plus-link{
  position: absolute;
/*    width: 100%; */
  height: 100%;
  top: 0.3rem;
  bottom: 0; 
  transform: translateY(100%);
}
.box-fb{
  position: relative;
  overflow: hidden;  
}
.box-fb img{
  width: 100%;
  height:30vh;
}
.box-fb .over-layer{
  width: 100%;
  height: 30vh;
  position: absolute;
  top: 0;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  transition: all 0.5s ease 0s;
}
 
.box-fb .box-content{
  width: 100%;
  position: absolute;
  top: -100%;
  padding: 5px 20px;
  transition: all 0.5s ease 0s;
}
 
.box-fb .box-content:after{
  content: "";
  width: 30%;
  display: block;
  margin-top: 20px;
  border-bottom: 2px solid #fff;
}
.box-fb .title{
  font-size: 16px;
  font-family: 'Libre Franklin', sans-serif ;
  color: #fff;
  margin-bottom: 5px;
  margin-top : 35px;
  text-transform: capitalize;
}
 
.box-fb .links{
  padding: 0 30px 0 0;
  margin: 0;
  list-style: none;
  width: 100%;
  position: absolute;
  bottom: -100%;
  text-align: right;
  transition: all 0.5s ease 0s;
}
.image-entreprise-gst img{
  height : 27vh;
  width : 50vh;
   }
   .card-image {
    width: 100%;
    height: 20vh;
    border-radius: 5px 5px 0 0;
  }
  
   .card-image img {
    width: 100%;
    max-width: 100%;
    height: 20vh;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s;
  }
  }
/*! landscape ipad  */
@media only screen and 
(orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (device-aspect-ratio: 4/3) {
    .nav-link{
      color: #212121 !important;
      font-weight: 300;
      font-size : 0.8rem;
      transition: all 200ms linear;
      font-family: 'Libre Franklin', sans-serif;
      margin : 0 !important;
      padding : 0 !important;
     
    }
    
.carousel-item,
.carousel-item img {
  height: 50vh;
}

.text-container {
  width: 150px;
  height: 50px;
  position: absolute;
   bottom: 25%;
   left: 55%;
   
 
}
.carousel-indicators > *{
  display: none;
}
.carousel-caption {
      
  top: 40vh;
  left: 0; 
      font-size : 0.9rem; 
      opacity:0;   
      -webkit-animation:fadeIn ease-in 1;   
      -moz-animation:fadeIn ease-in 1;
      animation:fadeIn ease-in 1;
    
      -webkit-animation-fill-mode:forwards;   
      -moz-animation-fill-mode:forwards;
      animation-fill-mode:forwards;
    
      -webkit-animation-duration:1s;
      -moz-animation-duration:1s;
      animation-duration:1s;
  
}
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.box {
  width: 51vh;
  height:75px;
  position: absolute;
  top: 0;
  left: 0; 
  background: #FF2B3A; 
 
 
  
}

 .bg-box{
  margin: -1rem 0.5rem;
  transform: translateY(-100px);
  animation: 0.4s slideIn ease-in-out forwards 1s;
  opacity: 0;
  
 }

@keyframes slideIn {
 
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


.overlay {
   
  z-index: 9;
  padding: 0.2rem 1.5rem;
   height: 100%;
  background: #ffffff;
  width :50vh;
  block-size: fit-content;
  block-size:-webkit-max-content;
  block-size: -moz-max-content;
  transform: translateX(-100px);
  animation: 0.5s slideIn ease-in-out forwards 1s;
  opacity: 0; 
}
.sparation{
  display: none;
}
.team{
  text-align:center;
  background-color: #F5F7F8;
  height : 100%;
}
 
.team h3{
  text-align: center;
  padding: 0 0.1rem;
  font-size: 0.9rem;
}

h3.emails-team{ 
  font-weight: 100;
  font-size: 0.9rem;
}
.image-entreprise-gst img{
  height : 37vh;
  width : 80vh;
   }
   .categories img.img-categories {
    width: 100%;
   height: 35%;
  }
  .products img.products-img{
    width: 100%;
    height: 40%;
  }
  #img-banner{
    height:50vh; 
  }
  .body-slogan {
    text-align:center;
    background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
    color:#FF2B3A; 
    font-weight:300;
    font-size:32px; 
    padding-top:20vh;
    height:50vh; 
    width : 90%;
    margin : 0 auto;
    overflow:hidden;
     
  }
  .plus-link{
    position: absolute;
  /*    width: 100%; */
    height: 100%;
    top: 0.3rem;
    bottom: 0; 
    transform: translateY(100%);
  }
  .dropdown-menu.show {
    display: contents;
  }
  .box-fb{
    position: relative;
    overflow: hidden;  
  }
  .box-fb img{
    width: 100%;
    height:40vh;
  }
  .box-fb .over-layer{
    width: 100%;
    height: 40vh;
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.7);
    opacity: 0;
    transition: all 0.5s ease 0s;
  }
   
  .box-fb .box-content{
    width: 100%;
    position: absolute;
    top: -100%;
    padding: 5px 20px;
    transition: all 0.5s ease 0s;
  }
   
  .box-fb .box-content:after{
    content: "";
    width: 30%;
    display: block;
    margin-top: 20px;
    border-bottom: 2px solid #fff;
  }
  .box-fb .title{
    font-size: 16px;
    font-family: 'Libre Franklin', sans-serif ;
    color: #fff;
    margin-bottom: 5px;
    margin-top : 35px;
    text-transform: capitalize;
  }
   
  .box-fb .links{
    padding: 0 30px 0 0;
    margin: 0;
    list-style: none;
    width: 100%;
    position: absolute;
    bottom: -100%;
    text-align: right;
    transition: all 0.5s ease 0s;
  }
  .card-image {
    width: 100%;
    height: 25vh;
    border-radius: 5px 5px 0 0;
  }
  
   .card-image img {
    width: 100%;
    max-width: 100%;
    height: 25vh;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s;
  }
  
}
 
/*! portrait iphone x*/
@media only screen 
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation: portrait) 
 and (-webkit-min-device-pixel-ratio: 3)
{
  .card-image {
    width: 100%;
    height: 25vh;
    border-radius: 5px 5px 0 0;
  }
  
   .card-image img {
    width: 100%;
    max-width: 100%;
    height: 25vh;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s;
  }
}
    /*! landscape iphone x  */
 @media only screen 
 and (min-device-width : 375px)
 and (max-device-width : 812px)
 and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 3)
 {
  .nav-link{
    color: #212121 !important;
    font-weight: 300;
    font-size : 0.8rem;
    transition: all 200ms linear;
    font-family: 'Libre Franklin', sans-serif;
    margin : 0 !important;
    padding : 0 !important;
   
  }
  .carousel-caption button, 
  .carousel-caption button:hover{
    border:none; 
  }
 
  
   
   .carousel-item {
     height : 74vh; 
   
   }
   .carousel-control-prev,
   .carousel-control-next{
     
     background :rgba(51,51,51,.5);
     margin : 30vh 0rem;
     
     padding : 0.5rem 0rem;
     height: fit-content; 
     height: -moz-max-content;
   }
   
 
   div#carousel-item  img {
     height : 60vh;
    }
    
 @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 
  
 
 .carousel-caption {
   -webkit-animation-delay: 0.7s;
   -moz-animation-delay: 0.7s;
   animation-delay: 0.7s;
 }
 
   .carousel-caption {
     position: absolute;
     top: 57vh;
     left: 0;
     font-size : 0.9rem; 
     opacity:0;   
     -webkit-animation:fadeIn ease-in 1;   
     -moz-animation:fadeIn ease-in 1;
     animation:fadeIn ease-in 1;
   
     -webkit-animation-fill-mode:forwards;   
     -moz-animation-fill-mode:forwards;
     animation-fill-mode:forwards;
   
     -webkit-animation-duration:1s;
     -moz-animation-duration:1s;
     animation-duration:1s;
   } 
 
   .carousel-caption::after{
     content: '>';
     transform: translateX(100px);
     animation: 0.5s slideIn ease-in-out forwards 0.5s;
   }
   .carousel-indicators > *{
     display: none;
   }  
  .text-container {
     position: absolute;
     top: 56vh;
     left :0;
   }
   .box {
     width: 100vw; 
     height :80vh;
     position: absolute;
     top: 0;
     left: 0; 
     background: #FF2B3A; 
    }
   
    .bg-box{ 
      margin: 0!important;
       
     transform: translateX(100px);
     animation: 0.5s slideIn ease-in-out forwards ;
     opacity: 0;
     
    }
    
 .overlay {
    z-index: 9; 
    background: #ffffff;  
    transform: translateX(100px);
   animation: 0.5s slideIn ease-in-out forwards 0.5s;
   opacity: 0;  
   height:10vh;
   width: 90vw; 
   left: 34%;
   padding : 0.2rem 1rem;
  top : 0%;
  margin-top: -0.7rem;
 }
  
  
   
h3.h4.font.titre-acc {
     font-size: 1rem;
 }
 hr.sparation{
   display:none;
 }
 
 .plus-link{
   position: absolute;
 /*    width: 100%; */
   height: 100%;
   top: 1rem;
   bottom: 0; 
   transform: translateY(100%);
 }
    
 .sparation{
  display: none;
}
.team{
  text-align:center;
  background-color: #F5F7F8;
  height : 70vh;
}
 
.team h3{
  text-align: center;
  padding: 0 0.1rem;
  font-size: 1rem;
}

h3.emails-team{ 
  font-weight: 100;
  font-size: 1rem;
}
.image-entreprise-gst img{
  height : 37vh;
  width : 80vh;
   }
   .categories img.img-categories {
    width: 100%;
   height: 35%;
  }
  .products img.products-img{
    width: 100%;
    height: 40%;
  }
  #img-banner{
    height:50vh; 
  }
  .body-slogan {
    text-align:center;
    background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
    color:#FF2B3A; 
    font-weight:300;
    font-size:32px; 
    padding-top:20vh;
    height:50vh; 
    width : 90%;
    margin : 0 auto;
    overflow:hidden;
     
  }
  .dropdown-menu.show {
    display: contents;
  }
  .box-fb{
    position: relative;
    overflow: hidden;  
  }
  .box-fb img{
    width: 100%;
    height: 60vh;
  }
  .box-fb .over-layer{
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.7);
    opacity: 0;
    transition: all 0.5s ease 0s;
  }
   
  .box-fb .box-content{
    width: 100%;
    position: absolute;
    top: -100%;
    padding: 5px 20px;
    transition: all 0.5s ease 0s;
  }
   
  .box-fb .box-content:after{
    content: "";
    width: 30%;
    display: block;
    margin-top: 20px;
    border-bottom: 2px solid #fff;
  }
  .box-fb .title{
    font-size: 16px;
    font-family: 'Libre Franklin', sans-serif ;
    color: #fff;
    margin-bottom: 5px;
    margin-top : 35px;
    text-transform: capitalize;
  }
   
  .box-fb .links{
    padding: 0 30px 0 0;
    margin: 0;
    list-style: none;
    width: 100%;
    position: absolute;
    bottom: -100%;
    text-align: right;
    transition: all 0.5s ease 0s;
  }
  .card-image {
    width: 100%;
    height: 37vh;
    border-radius: 5px 5px 0 0;
  }
  
   .card-image img {
    width: 100%;
    max-width: 100%;
    height: 37vh;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s;
  }
}
/*! phone landscape*/
@media screen 
and (max-device-height : 360px)
and (max-device-width : 640px)
       and (-webkit-device-pixel-ratio: 3) 
       and (orientation : landscape){
        
        .carousel-control-prev,
        .carousel-control-next{
          
          background :rgba(51,51,51,.5);
          margin : 18vh 0rem;
          
          padding : 0.5rem 0rem;
          height: fit-content; 
          height: -moz-max-content;
        }
        .carousel-item {
          height : 74vh; 
        
        }
      
        div#carousel-item  img {
          height : 60vh;
         }
         
      @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
      @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
      @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
      
      
     
      .carousel-caption {
        -webkit-animation-delay: 0.7s;
        -moz-animation-delay: 0.7s;
        animation-delay: 0.7s;
      }
      
        .carousel-caption {
          position: absolute;
          top: 57vh;
          left: 0;
          font-size : 0.9rem; 
          opacity:0;   
          -webkit-animation:fadeIn ease-in 1;   
          -moz-animation:fadeIn ease-in 1;
          animation:fadeIn ease-in 1;
        
          -webkit-animation-fill-mode:forwards;   
          -moz-animation-fill-mode:forwards;
          animation-fill-mode:forwards;
        
          -webkit-animation-duration:1s;
          -moz-animation-duration:1s;
          animation-duration:1s;
        } 
      
        .carousel-caption::after{
          content: '>';
          transform: translateX(100px);
          animation: 0.5s slideIn ease-in-out forwards 0.5s;
        }
        .carousel-indicators > *{
          display: none;
        }  
       .text-container {
          position: absolute;
          top: 56vh;
          left :0;
        }
        .box {
          width: 100vw; 
          height :80vh;
          position: absolute;
          top: 0;
          left: 0; 
          background: #FF2B3A; 
         }
        
         .bg-box{ 
           margin: 0!important;
            
          transform: translateX(100px);
          animation: 0.5s slideIn ease-in-out forwards ;
          opacity: 0;
          
         }
         
      .overlay {
         z-index: 9; 
         background: #ffffff;  
         transform: translateX(100px);
        animation: 0.5s slideIn ease-in-out forwards 0.5s;
        opacity: 0;  
        height:10vh;
        width: 90vw; 
        left: 34%;
        padding : 0.2rem 1rem;
       top : 0%;
       margin-top: -0.7rem;
      }
      .plus-link{
        position:  relative;
      /*    width: 100%; */
        
      }
     
      hr.separte{
           display:none;
         }
    
     .team{
       text-align:center;
       background-color: #F5F7F8;
       height : 70vh;
     }
      
     .team h3{
       text-align: center;
       padding: 0 0.1rem;
       font-size: 1rem;
     }
     
     h3.emails-team{ 
       font-weight: 100;
       font-size: 1rem;
     }
     .image-entreprise-gst img{
       height : 37vh;
       width : 80vh;
        }
        .categories img.img-categories {
         width: 100%;
        height: 35%;
       }
       .products img.products-img{
         width: 100%;
         height: 50%;
       }
       #img-banner{
         height:50vh; 
       }
       .body-slogan {
         text-align:center;
         background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
         color:#FF2B3A; 
         font-weight:300;
         font-size:32px; 
         padding-top:20vh;
         height:50vh; 
         width : 90%;
         margin : 0 auto;
         overflow:hidden;
          
       }
       .dropdown-menu.show {
         display: contents;
       }
       .box-fb{
        position: relative;
        overflow: hidden;  
      }
      .box-fb img{
        width: 100%;
        height: 50vh;
      }
      .box-fb .over-layer{
        width: 100%;
        height: 50vh;
        position: absolute;
        top: 0;
        background: rgba(0,0,0,0.7);
        opacity: 0;
        transition: all 0.5s ease 0s;
      }
       
      .box-fb .box-content{
        width: 100%;
        position: absolute;
        top: -100%;
        padding: 5px 20px;
        transition: all 0.5s ease 0s;
      }
       
      .box-fb .box-content:after{
        content: "";
        width: 30%;
        display: block;
        margin-top: 20px;
        border-bottom: 2px solid #fff;
      }
      .box-fb .title{
        font-size: 16px;
        font-family: 'Libre Franklin', sans-serif ;
        color: #fff;
        margin-bottom: 5px;
        margin-top : 35px;
        text-transform: capitalize;
      }
       
      .box-fb .links{
        padding: 0 30px 0 0;
        margin: 0;
        list-style: none;
        width: 100%;
        position: absolute;
        bottom: -100%;
        text-align: right;
        transition: all 0.5s ease 0s;
      }
      .card-image {
        width: 100%;
        height: 25vh;
        border-radius: 5px 5px 0 0;
      }
      
       .card-image img {
        width: 100%;
        max-width: 100%;
        height: 25vh;
        border-radius: 5px 5px 0 0;
        transition: all 0.3s;
      }
       }
      a.dropdown-item:active {
        color: #FF2B3A;
        text-decoration: none;
        background-color: #F8F9FA;
    }
    
 
/***************************/